<template>
  <div class="about">
    <GmapMap
      :center="centerCoordenates"
      :zoom="centerZoom"
      map-type-id="terrain"
      style="width: 100%; height: 65vh"
    >
      <cluster :gridSize="30" :zoomOnClick="true" :maxZoom="20">
        <GmapMarker
          v-for="(visita, index) in prop_markers_and_pedido"
          :key="index"
          :position="{ lat: visita.lat, lng: visita.lng }"
          :clickable="true"
          :icon="fnGetIcon(visita.motivo_de_visita_id)"
          :draggable="false"
          @click="toggleInfo(visita), (flagCurrentIndex = index)"
          @mouseover="fnDrawPolyPath(index)"
      /></cluster>
      <gmap-polyline
        v-if="poplyLineFlag && prop_polyline_enable == true"
        :path="deliveryPath"
        v-bind:options="{
          strokeWeight: 5,
          strokeColor: deliveryPathColor,
          strokeOpacity: 0.7,
          icons: [
            {
              icon: {
                path: figura_camino_polyline,
                scale: 3,
                fillColor: deliveryPathColor,
                fillOpacity: 1,
              },
              offset: '0',
              repeat: '100px',
            },
          ],
        }"
        ref="polyline"
        v-on:click="poplyLineFlag = false"
      ></gmap-polyline>
      <gmap-info-window
        v-if="prop_otros_tipos_de_visita == false"
        :options="infoOptions"
        :position="infoPosition"
        :opened="infoOpened"
        @closeclick="infoOpened = false"
      >
        <v-card v-if="infoContent" class="">
          <v-card-title> {{ infoContent.consejera_nombre }}</v-card-title>
          <v-card-text>
            <div>
              <strong>Visita:</strong><br />
              <div>
                {{ infoContent.motivo_de_visita }}
                <v-icon :class="fnGetColor(infoContent.motivo_de_visita_id)"
                  >mdi-label-variant</v-icon
                >
              </div>
              {{ fnGetDate(infoContent.fecha) }}
            </div>

            <div v-if="infoContent.usuario_nombre">
              <strong>Supervisora:</strong> <br />
              {{ infoContent.usuario_nombre }}
            </div>

            <div v-if="infoContent.supervisora">
              <strong>Supervisora:</strong> <br />
              {{ infoContent.supervisora.nombre_completo }}
            </div>
          </v-card-text>
          <v-card-actions v-if="prop_visitadetalle == false">
            <v-spacer></v-spacer>
            <v-btn
              title="Visita previa"
              :disabled="flagPrevVisita"
              @click="fnPrevVisita()"
              small
              elevation="1"
              text
              color="white"
            >
              <v-icon color="primary">mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn
              title="Siguiente visita"
              :disabled="flagNextVisita"
              @click="fnNextVisita()"
              small
              elevation="1"
              text
              color="white"
            >
              <v-icon color="primary">mdi-arrow-right</v-icon>
            </v-btn>

            <v-btn
              target="_blank"
              :href="'/visita/' + infoContent.id"
              text
              color="primary"
            >
              Ver mas
            </v-btn>
          </v-card-actions>
        </v-card>
      </gmap-info-window>
    </GmapMap>
  </div>
</template>
<script>
import moment from 'moment';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import { gmapApi } from 'vue2-google-maps';
export default {
  components: {
    cluster: GmapCluster,
  },
  data() {
    return {
      gmapCenter: { lat: 14.599251, lng: -90.55084 },
      flagCurrentIndex: 0,
      // polyline
      figura_camino_polyline: null,
      poplyLineFlag: false,
      deliveryPath: [],
      deliveryPathColor: '#4DD0E1',
      //
      // place detail
      infoPosition: null,
      infoContent: null,
      infoOpened: false,
      infoCurrentKey: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  name: 'mapa',
  props: {
    prop_reunion_consejeras: {
      type: Boolean,
    },
    prop_polyline_enable: {
      type: Boolean,
    },
    prop_vista_inicio_de_labores: {
      type: Boolean,
    },
    prop_otros_tipos_de_visita: {
      type: Boolean,
    },
    prop_center: {
      type: Object,
    },
    prop_visitadetalle: {
      type: Boolean,
    },
    prop_maker_color: {
      type: Number,
    },
    prop_markers: {
      type: Array,
    },
  },
  watch: {
    prop_markers() {
      this.deliveryPath = [];
    },
  },
  methods: {
    fnGetDate(date) {
      return date ? moment(date).utc().format('DD-MMMM  HH:mm a') : '';
    },
    fnGetColor(motivo) {
      return this.$store.state.visitas.filter(
        (store_visita) => store_visita.id == motivo
      )[0].textColorClass;
    },
    fnGetIcon(motivo) {
      console.log(motivo);
      if (!isNaN(motivo)) {
        return this.$store.state.visitas.filter(
          (store_visita) => store_visita.id == motivo
        )[0].marker;
      } else {
        return this.$store.state.OtrasVisitas.filter(
          (store_visita) => store_visita.id == motivo
        )[0].marker;
      }
    },
    /**
     * polyline & get info
     */

    toggleInfo(marker) {
      this.infoPosition = { lat: marker.lat, lng: marker.lng };
      this.infoContent = marker;
      if (this.infoCurrentKey == marker.id) {
        // this.infoOpened = !this.infoOpened;
      } else {
        this.infoOpened = true;
        this.infoCurrentKey = marker.id;
      }
    },
    fnDrawPolyPath(index) {
      if (this.prop_polyline_enable == true) {
        this.poplyLineFlag = true;
        this.figura_camino_polyline =
          this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW;

        if (this.prop_markers[index + 1] != undefined) {
          this.deliveryPath = [];
          this.deliveryPath.push({
            lat: this.prop_markers[index].lat,
            lng: this.prop_markers[index].lng,
          });
          this.deliveryPath.push({
            lat: this.prop_markers[index + 1].lat,
            lng: this.prop_markers[index + 1].lng,
          });
        } else {
          console.log('no hay siguiente entrega');
        }
      }
    },
    fnNextVisita() {
      console.log(this.flagCurrentIndex);
      console.log(this.prop_markers[this.flagCurrentIndex + 1]);
      if (this.prop_markers[this.flagCurrentIndex + 1] != undefined) {
        this.flagCurrentIndex += 1;
        this.gmapCenter.lat = this.prop_markers[this.flagCurrentIndex].lat;
        this.gmapCenter.lng = this.prop_markers[this.flagCurrentIndex].lng;

        this.toggleInfo(this.prop_markers[this.flagCurrentIndex]);
        this.fnDrawPolyPath(this.flagCurrentIndex);
      }
    },
    fnPrevVisita() {
      if (this.prop_markers[this.flagCurrentIndex - 1] != undefined) {
        this.flagCurrentIndex -= 1;
        this.gmapCenter.lat = this.prop_markers[this.flagCurrentIndex].lat;
        this.gmapCenter.lng = this.prop_markers[this.flagCurrentIndex].lng;

        this.toggleInfo(this.prop_markers[this.flagCurrentIndex]);
        this.fnDrawPolyPath(this.flagCurrentIndex);
      }
    },
    /**
     * polyline & get info
     */
  },
  computed: {
    google() {
      return new gmapApi();
    },
    flagNextVisita() {
      if (this.prop_markers[this.flagCurrentIndex + 1] != undefined) {
        return false;
      } else return true;
    },
    flagPrevVisita() {
      if (this.prop_markers[this.flagCurrentIndex - 1] != undefined) {
        return false;
      } else return true;
    },
    centerZoom() {
      if (this.prop_center && this.prop_center.zoom) {
        return this.prop_center.zoom;
      }
      return 7;
    },
    centerCoordenates() {
      if (this.prop_center && this.prop_center.lat) {
        return { lat: this.prop_center.lat, lng: this.prop_center.lng };
      }
      if (this.gmapCenter && this.gmapCenter.lat) {
        return { lat: this.gmapCenter.lat, lng: this.gmapCenter.lng };
      }
      if (
        this.prop_markers.length == 0 ||
        this.prop_markers[0].lat_llegada == 0
      ) {
        return { lat: 15.5670864, lng: -90.6112826 };
      } else {
        if (this.prop_markers[0].lat_llegada) {
          return {
            lat: this.prop_markers[0].lat_llegada,
            lng: this.prop_markers[0].lng_llegada,
          };
        } else {
          return {
            lat: this.prop_markers[0].lat,
            lng: this.prop_markers[0].lng,
          };
        }
      }
    },
    prop_markers_and_pedido() {
      let makers = this.prop_markers;
      let local_prop_marker_color = 'a';

      /** validacion reunion de consejeras */
      if (this.prop_reunion_consejeras == true) {
        makers = [];
      }

      if (this.prop_maker_color) {
        local_prop_marker_color = this.prop_maker_color;
      }
      this.prop_markers.forEach((visita) => {
        if (visita.pedido) {
          makers.push({
            lat: parseFloat(visita.pedido.DELIVERY_LAT),
            lng: parseFloat(visita.pedido.DELIVERY_LON),
            motivo_de_visita_id: local_prop_marker_color,
          });
        }
        /** REUNION DE CONSEJERAS */
        if (visita.lat_llegada != undefined && visita.lat_llegada != 0) {
          console.log(visita.lat_llegada, visita.lng_llegada);
          makers.push({
            lat: parseFloat(visita.lat_llegada),
            lng: parseFloat(visita.lng_llegada),
            motivo_de_visita_id: 'e',
          });
        }
        /** REUNION DE CONSEJERAS */
        if (visita.lat_inicio != undefined && visita.lat_inicio != 0) {
          console.log(visita.lat_inicio, visita.lng_inicio);
          makers.push({
            lat: parseFloat(visita.lat_inicio),
            lng: parseFloat(visita.lng_inicio),
            motivo_de_visita_id: 'e',
          });
        }
        /**  inicio de labores */
        if (visita.supervisora && this.prop_vista_inicio_de_labores == true) {
          if (visita.inic_lab_fecha) {
            makers.push({
              lat: visita.inic_lab_lat,
              lng: visita.inic_lab_lng,
              motivo_de_visita_id: 'f',
            });
          }

          if (visita.fecha_fin) {
            makers.push({
              lat: visita.lat_fin,
              lng: visita.lng_fin,
              motivo_de_visita_id: 'h',
            });
          }
        }
      });

      return makers;
    },
  },
  mounted() {},
};
</script>
